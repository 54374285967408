import React, {useState} from "react";
import yaml from "js-yaml";
import axios from "axios";
import {useAsyncEffect} from "use-async-effect";

// hooks

export function JSONLoader({src, component: Comp, toProps, ...rest}) {
    // const src = "https://escher.ge.ngrok.io/files/fast_nerf/fast_nerf/vqn-dash/demo_data/files/view_1.json"
    const [{loading, error = null, ...props}, setData] = useState({loading: true})

    useAsyncEffect(async () => {
        const {status, data} = await axios.get(src);
        const _props = toProps ? toProps(data) : {data}
        setData({loading: false, error: status < 400, ..._props})
    }, [src, toProps])

    return <Comp loading={loading} error={error} {...props} {...rest}/>
}

export function YAMLLoader({src, component: Comp, toProps, ...rest}) {
    // const src = "https://escher.ge.ngrok.io/files/fast_nerf/fast_nerf/vqn-dash/demo_data/files/view_1.json"
    const [{loading, error = null, ...props}, setData] = useState({loading: true})

    useAsyncEffect(async () => {
        const {status, data} = await axios.get(src);
        const yamlData = yaml.load(data);
        const _props = toProps ? toProps(yamlData) : {data}
        setData({loading: false, error: status < 400, ..._props})
    }, [src, toProps])

    return <Comp loading={loading} error={error} {...props} {...rest}/>
}


export function BatchJsonLoader({urls = [], component: Comp, toProps, ...rest}) {
    // const src = "https://escher.ge.ngrok.io/files/fast_nerf/fast_nerf/vqn-dash/demo_data/files/view_1.json"
    const [{loading, error = null, data_list = []}, setData] = useState({loading: true})

    useAsyncEffect(async () => {
        const calls = urls.map(axios.get)
        setData({loading: true, error: null, data_list: []})
        for (let p of calls) {
            const {status, data} = await p;
            // data_list.append(data);
            // const _props = mapProps ? mapProps(data) : {data}
            setData(({data_list: pre_data_list = []}) => {
                    return {
                        loading: false,
                        error: status < 400,
                        data_list: [...pre_data_list, data]
                    }
                }
            )
        }
    }, [urls, toProps])

    return toProps
        ? <Comp loading={loading} error={error} {...toProps(data_list)} {...rest}/>
        : <Comp loading={loading} error={error} data={data_list} {...rest}/>

}

export function ImgLoader({url, component: Comp, toProps, ...rest}) {
    // const src = "https://escher.ge.ngrok.io/files/fast_nerf/fast_nerf/vqn-dash/demo_data/files/view_1.json"
    const [{loading, error = null, ...props}, setData] = useState({loading: true})

    useAsyncEffect(async () => {
        const {status, data} = await axios.get(url);
        const _props = toProps ? toProps(data) : {data}
        setData({loading: false, error: status < 400, ..._props})
    }, [url, toProps])

    return <Comp loading={loading} error={error} {...props} {...rest}/>
}
