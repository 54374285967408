import React, {useState} from 'react'
import PointCloudView from "./components/_point-cloud";
import {BatchJsonLoader} from "../../loaders/_json-loader";
import Editor from "@monaco-editor/react";
import {parse} from "query-string";
import {document} from "browser-monads";

function text2urls(text) {
    return text.split('\n').map(line => line.trim()).filter(l => (!!l))
}

const defaultText = `
https://escher.ge.ngrok.io/files/fast_nerf/fast_nerf/vqn-dash/demo_data/files/view_1.json
https://escher.ge.ngrok.io/files/fast_nerf/fast_nerf/vqn-dash/demo_data/files/view_2.json
`

export default function PointCloudDemo({}) {
    const {pcdPath = ""} = parse(document.location.search)

    const [{text, urls}, setState] = useState(
        {
            text: pcdPath || defaultText,
            urls: text2urls(pcdPath || defaultText)
        });

    function onChange(newText, e) {
        console.log(newText)
        setState({
            text: newText,
            urls: text2urls(newText)
        });
    }

    if (typeof window == "undefined") return <div>loading</div>;
    return <div style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        height: "100%",
        width: "100%"
    }}>
        <Editor height="10vh" defaultLanguage="markdown" defaultValue={text} onChange={onChange}/>
        <BatchJsonLoader urls={urls} component={PointCloudView} width={640} height={480}
                         toProps={(data_list) => ({views: data_list})}/>
    </div>
}